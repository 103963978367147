'use strict';
var calColorCache = {};
var calEventCache = {};
var isResize = false;
var $hotelSelect = false;
var $startDateInputTemp = false;

(function ($) {
    ;

    var s8Forms = function ($form) {
        this.$form = $form;

        // settings by data attributes
        var settingsData = this.$form.data();
        var settings = settingsData.s8formsSettings;
        this.uid = settingsData.s8formsUid;
        
        this.lang = settingsData.s8formsLang || $('html').attr('lang') || 'de';
        this.dateFormat = settingsData.s8formsDateFormat || 'DD.MM.YYYY';

        if (typeof settings.validation === "undefined") {
            console.error('s8forms.js:  Settings Validation / JSON Variable missing');
            return;
        }

        this.validationSettings = settings.validation;
        this.ahorns8colorsUrl = location.origin + '/index.php?id=' + settings.ahorns8colorsPageUid;

        // init
        this.initCalendar();

        $form.on('submit', function (e) {
            e.preventDefault();
            this.process();
        }.bind(this));

        this.translations = {
            ctaMessage: s8Lang.restriction.ctaMessage,
            minLosMessage: s8Lang.restriction.minLosMessage,
            maxLosMessage: s8Lang.restriction.maxLosMessage,
            closeWindow: s8Lang.restriction.closeWindow,
            clock: s8Lang.clock
        };
    };

    s8Forms.prototype = {
        $targetForm: $('#BookingForm'),

        initCalendar: function () {

            var $document = $(document);

            var $display = $('.onm_booker_show_single', this.$form);
            var $inContent = $('.booker-in-content', this.$form);

            var $startDateInput = $(this.getIdSelector('StartDate'));
            var $endDateInput = $(this.getIdSelector('EndDate'));

            // set startDate
            var startDate = moment(this.validationSettings.travelPeriods[0][0], "YYYY-MM-DD");
            if (startDate < moment()) {
                startDate = moment();
            }
            
            // moment lang
            moment.locale(this.lang);
            if (this.dateFormat) {
                moment.updateLocale(this.lang, {
                    longDateFormat : {
                        LT: "h:mm A",
                        LTS: "h:mm:ss A",
                        L: this.dateFormat,        // customized
                        l: "M/D/YYYY",
                        LL: "MMMM Do YYYY",
                        ll: "MMM D YYYY",
                        LLL: "MMMM Do YYYY LT",
                        lll: "MMM D YYYY LT",
                        LLLL: "dddd, MMMM Do YYYY LT",
                        llll: "ddd, MMM D YYYY LT"
                    }
                });
            }

            // calendar - messages
            var calendarDisplayMessages = {
                //'restriction.minRange': s8Lang.restriction.minRange.replace('{0}', this.validationSettings.minLengthOfStay),
                'restriction.maxRange': s8Lang.restriction.minRange.replace('{0}', this.validationSettings.maxLengthOfStay),
                'adaptation.dateRange': s8Lang.adaptation.dateRange,

                'minLosMessage': s8Lang.restriction.minLosMessage,
                'maxLosMessage': s8Lang.restriction.maxLosMessage,
                'closeWindow': s8Lang.restriction.closeWindow
            };

            // calendar - options
            var calendarOptions = {
                lang: this.lang,

                dateFormat: 'L',

                width: this.$form.data('s8forms-width'),
                position: 'bottom-left',

                keepPopupOpen: true,
                popupRangeSelect: true,

                rangeToElement: $endDateInput,

                minRange: this.validationSettings.minLengthOfStay,
                maxRange: this.validationSettings.maxLengthOfStay,

                dateRanges: this.validationSettings.travelPeriods,

                value: [
                    startDate.format(this.dateFormat),
                    startDate.add(this.validationSettings.minLengthOfStay, 'days').format(this.dateFormat)
                ]
            };

            // calendar - events
            $startDateInput.onmCal(calendarOptions)
                .onmCal('resetValue', true)

                .on('onm.cal.message', function (evt, messageType, messageKey) {
                    if (!calendarDisplayMessages[messageType + '.' + messageKey]) {
                        return;
                    }

                    toastr.clear();
                    toastr.info(calendarDisplayMessages[messageType + '.' + messageKey], 'Hinweis:');
                })

                .on('onm.cal.popupShown', function () {
                    $document.on('focusin.bs.modal', this.stopBootstrapFocus);
                }.bind(this))

                .on('onm.cal.popupHidden', function () {
                    $document.off('focusin.bs.modal', this.stopBootstrapFocus);

                    var $dayOfWeek = moment($startDateInput.val(), this.dateFormat).day();
                    if (Array.isArray(this.validationSettings.daysAllowedArrival) && !this.validationSettings.daysAllowedArrival[$dayOfWeek]) {
                        $startDateInput.onmCal('resetValue', true);

                        toastr.clear();
                        toastr.error(s8Lang.restriction.arrivalDay.replace('{0}', this.getDayNames()), 'Hinweis:');
                    }
                }.bind(this))

                .on('onm.cal.monthChange onm.cal.created', function (evt, startMonth, monthCount) {
                    // only re-load colors/events of visible booker by clicking on input "onm_booker_show_single"
                    $startDateInput.parents('.booker').find('.onm_booker_show_single').on('click', function() {
                        if ($startDateInput.attr('id') != $startDateInputTemp) {
                            this.updateCalendarColors($startDateInput, startMonth, monthCount);
                            this.updateCalendarEvents($startDateInput, startMonth, monthCount);
                            $startDateInputTemp = $startDateInput.attr('id');
                        }
                        
                        // scroll booker/datepicker into view
                        if (ResponsiveBootstrapToolkit.is('xs')) {
                            $('.modal.show').animate({
                                scrollTop: 165
                            }, 1000);
                        }

                    }.bind(this));
                    
                    // only re-load colors/events of visible booker by clicking on next/previous month
                    if ($startDateInput.parents('.booker').is(":visible") && $startDateInput.attr('id') == $startDateInputTemp) {
                        this.updateCalendarColors($startDateInput, startMonth, monthCount);
                        this.updateCalendarEvents($startDateInput, startMonth, monthCount);
                    }
                }.bind(this))

                .on('onm.cal.created', function (e, container) {
                    // prepend message box to calendar 
                    this.addMessageBox($(container)); 
                    
                    // append legend to calendar
                    $($('.onm_booker_legend').get(0)).clone().appendTo($('.onm-cal', $(container)));
                }.bind(this))

                .on('onm.cal.change', function () {
                    this.checkDates(moment($startDateInput.val(), 'L'), moment($endDateInput.val(), 'L'), true);
                }.bind(this));
                
            $endDateInput.on('onm.cal.change', function () {
                this.checkDates(moment($startDateInput.val(), 'L'), moment($endDateInput.val(), 'L'), true);
            }.bind(this));

            $('body').on('click touchstart', '.cal-close-button .btn, #onm_booker_show_position_to', function () { 
                $startDateInput.onmCal('popupHide');
                $inContent.slideUp();
            });

            // calendar display - events
            $display.on('focus click', function (e) {
                if (!ResponsiveBootstrapToolkit.is('xs')) {
                    e.stopPropagation();
                    $startDateInput.onmCal('popupShow', true, $display);
                }

                // Show Booker in Content on Mobile but wait for Click/Focus
                $inContent.slideDown();
            });

            $display.on('change keyup keydown', function (e) {
                e.preventDefault();
            });

            // on date-range selection in cal
            var onRangeChange = function (e, rangeToDate) {
                var startDate = $startDateInput.val();
                var endDate = $endDateInput.val();
                
                if (startDate) {
                    var displayText = startDate + ' ' + s8Lang.until + ' ';
                    if (endDate) {
                        displayText += endDate;
                    } else {
                        displayText += '...';
                    }

                    $display.val(displayText);
                }

                if (endDate) {

                    // Close Calendar after end-date selection by user and date-range is valid!
                    if (this.validateNumOfPersons() && this.checkDates(moment(startDate, 'L'), moment(endDate, 'L'), true)) {
                        $startDateInput.onmCal('popupHide');
                        $inContent.slideUp();
                    }

                }
            };

            $startDateInput.on('onm.cal.change', $.proxy(onRangeChange, this));
            $endDateInput.on('onm.cal.change', $.proxy(onRangeChange, this));

            // Update Options between mobile/desktop viewports
            // Used to show Calendar directly in Content on Mobile Viewports instead as Popup (on Desktop Viewports > XS)
            var updateOnResize = function () {
                calendarOptions.value = [$startDateInput.val(), $endDateInput.val()];

                if (ResponsiveBootstrapToolkit.is('xs')) {
                    $startDateInput.onmCal('update', $.extend({}, calendarOptions, {
                        position: $inContent
                    }));
                } else {
                    $startDateInput.onmCal('update', calendarOptions);
                }
            };
            $document.on('resizeDebounced', updateOnResize);
            updateOnResize();

            // reset if view changed to mobile
            if (ResponsiveBootstrapToolkit.is('xs')) {
                $startDateInput.onmCal('resetValue', true);
            }

        },
        
        initTooltip: function ($el) {

            var $daysWithTooltipps = $el.parent().find('.onm-cal-popup .eventTooltips');

            $daysWithTooltipps.tooltip({
                animation: true,
                html: true,
                //delay: { "show": 100, "hide": 1000000 },
                container: 'body',
                template: '<div class="tooltip calTooltip" role="tooltip"><div class="tooltip-arrow"><span></span><span></span></div><div class="tooltip-inner"></div></div>',
                placement: function (tip, element) {
                    var scrollTop = $(window).scrollTop();
                    var viewportHeight = $(window).height();
                    var offsetTop = $(element).offset().top;
                    var spaceTop = offsetTop - scrollTop;
                    var spaceBottom = viewportHeight + scrollTop - offsetTop;

                    var viewPortWidth = $(window).width();
                    var offsetLeft = $(element).offset().left;
                    if (viewPortWidth - offsetLeft < 210) {
                        return 'left';
                    } else if (offsetLeft < 210) {
                        return 'right';
                    }
                    if (spaceTop > spaceBottom) {
                        return 'top';
                    } else {
                        return 'bottom';
                    }
                }
            });

        },
        setMonthEvent: function ($el, data) {
            var self = this;
            var momentDate = moment(data.month, "DD.MM.YYYY").hours(0).minutes(0).seconds(0).milliseconds(0);
            var startOfMonth = momentDate.startOf('month');
            var endOfMonth = startOfMonth.clone().endOf('month');

            $.each(data.result, function () {
                var $data = this;
                var start = moment.max(startOfMonth, moment($data.start, 'YYYYMMDD')).clone();
                var end = moment.min(endOfMonth, moment($data.end, 'YYYYMMDD'));
                var count = 0;
                while (start <= end) {
                    var $day = $el.onmCal('getDateElement', start);
                    count++;
                    //var title = '<h3>' + $data.title + '</h3><br />' + $data.description;
                    var title = "";
                    if ($data.startTime !== $data.endTime) {
                        title = '<h3 class="tt-headline count-'+count+'">' + $data.title + '</h3>\
                        <div class="tt-date-group tt-time">\
                        <span>' + $data.startTime + ' - ' + $data.endTime + ' ' + self.translations.clock + '</span>\
                        </div>\
                        <div class="tt-description">\
                        <span>' + $data.description + '</span>\
                        </div>';
                    } else {
                        title = '<h3 class="tt-headline count-'+count+'">' + $data.title + '</h3>\
                        <div class="tt-description">\
                        <span>' + $data.description + '</span>\
                        </div>';
                    }
                    if ($day.hasClass("eventTooltips")) {
                        title = $day.attr("title") + '<hr />' + title;
                    } else {
                        $day.addClass("eventTooltips");
                        $day.attr("data-html", "true");
                    }
                    $day.attr("title", title);
                    start.add(1, 'days');
                }
            });
        },
        checkDates: function (mFrom, mTo, showMessageInCalendar) {
            var self = this;
                self.hideMessageBox();
                
            var colorCacheIndex = self.getIdSelector('') + mFrom.clone().startOf('month').format('YYYY-MM-DD');
            
            if (calColorCache[colorCacheIndex]) {
                // check cta dates
                for (var i = 0; i < calColorCache[colorCacheIndex].closedToArrivalDates.length; i++) {
                    if (calColorCache[colorCacheIndex].closedToArrivalDates[i].date ===
                            mFrom.format('YYYY-MM-DD')) {
                        //toastr.clear();
                        //toastr.warning(String.format(
                        //    self.translations.ctaMessage,
                        //    mFrom.format('L')));
                        self.addMessage(
                                self.translations.ctaMessage.replace('{0}',
                                        mFrom.format('L')), 'warning');
                        return false;
                    }
                }

                // check minlos and maxlos
                for (var j = 0; j < calColorCache[colorCacheIndex].dailyRateLengthOfStay.length; j++) {
                    if (calColorCache[colorCacheIndex].dailyRateLengthOfStay[j].date === mFrom.format('YYYY-MM-DD')) {
                        var nights = mTo.diff(mFrom, 'days');
                        
                        if (typeof self.translations == 'undefined') {
                            console.error('self.translations is undefined');
                            return false;
                        }

                        if (calColorCache[colorCacheIndex].dailyRateLengthOfStay[j].maxLos > 0 && nights > calColorCache[colorCacheIndex].dailyRateLengthOfStay[j].maxLos) {
                            if (!showMessageInCalendar) {
                                toastr.clear();
                                toastr.warning(
                                        self.translations.maxLosMessage.replace('{0}',
                                                calColorCache[colorCacheIndex].dailyRateLengthOfStay[j].maxLos));
                            } else {
                                self.addMessage(
                                        self.translations.maxLosMessage.replace('{0}',
                                                calColorCache[colorCacheIndex].dailyRateLengthOfStay[j].maxLos),
                                        'info');
                            }
                            return false;
                        }

                        if (nights < calColorCache[colorCacheIndex].dailyRateLengthOfStay[j].minLos) {
                            if (!showMessageInCalendar) {
                                toastr.clear();
                                toastr.warning(
                                        self.translations.minLosMessage.replace('{0}',
                                                calColorCache[colorCacheIndex].dailyRateLengthOfStay[j].minLos));
                            } else {
                                self.addMessage(
                                        self.translations.minLosMessage.replace('{0}',
                                                calColorCache[colorCacheIndex].dailyRateLengthOfStay[j].minLos),
                                        'info');
                            }
                            return false;
                        }

                    }
                }
            }
            return true;
        },
        addMessageBox: function ($container) {  
            if ($('.cal-message-box', $container).length === 0) {  
              $('.onm-cal', $container).prepend('<div class="cal-message-box" />');  
            }  
        },
        hideMessageBox: function () {
            $('.cal-message-box').hide();
        },
        addMessage: function (text, status) {
            var $div = $('<div class="text-' + status + '">' + text + '</div>');
            $('.cal-message-box').html("").append($div).show();
        },
        updateCalendarColors: function ($el, startMonth, monthCount) {
            var self = this;

            var setMonthColor = function (colorData) {
                $.each(colorData,
                    function () {
                        $el.onmCal('setDateElementColors', this.date, this.bgColor, this.fontColor);
                    }
                );
            };

            var setCtaColor = function (ctaDates) {
                $.each(ctaDates, function () {
                    $el.onmCal('setDateElementColors', this.date, self.ctaBgColor, self.ctaFontColor);
                }); 
            };

            for (var i = 0; i < monthCount; i++) {
                var month = startMonth.clone().add(i, 'months').format('YYYY-MM-DD');

                if (calColorCache[self.getIdSelector('') + month]) {
                    setMonthColor(calColorCache[self.getIdSelector('') + month].webAvailabilityColors);
					setCtaColor(calColorCache[self.getIdSelector('') + month].closedToArrivalDates);
                    self.addMlosIcons($el, month);
                    console.info('Loaded calendar COLORS from CACHE.');
                } else {
                    $.ajax({
                        url: this.ahorns8colorsUrl,
                        data: {
                            type: '5001',
                            tx_ahorns8colors_availabilitycolors: {
                                dateString: moment(month, 'YYYY-MM-DD').format('DD.MM.YYYY'),
                                remoteScriptURL: $(this.getIdSelector('BookingUrl')).val() // 'http://192.168.50.169:8080/Ahorn-Source/'
                            }
                        },
                        success: function (msg) {
                            var json = $.parseJSON(msg.d)._embedded.rate_availability[0];
                            calColorCache[self.getIdSelector('') + json.webAvailabilityColors[0].date] = json;
                            setMonthColor(json.webAvailabilityColors);
                            setCtaColor(json.closedToArrivalDates);
                            self.addMlosIcons($el, json.webAvailabilityColors[0].date);
                            console.info('Loaded calendar COLORS from API.');
                        },

                        // we shouldn't get there, but if we do ignore any errors and proceed
                        error: function () {
                            console.error('s8forms - farben konnten nicht aktualisiert werden URL: ' + this.ahorns8colorsUrl);
                            // this.process();
                        }.bind(this)
                    });

                }
            }
        },
        
        updateCalendarEvents: function ($el, startMonth, monthCount) {
            var self = this;
            for (var i = 0; i < monthCount; i++) {
                var month = startMonth.clone().add(i, 'months').format('DD.MM.YYYY');
                if (calEventCache[self.getIdSelector('') + month]) {
                    self.setMonthEvent($el, calEventCache[self.getIdSelector('') + month]);
                    if (!ResponsiveBootstrapToolkit.is('xs')) {
                        self.initTooltip($el);
                    }
                    console.info('Loaded calendar EVENTS from CACHE.');
                } else {
                    $.ajax({
                        url: this.ahorns8colorsUrl,
                        data: {
                            type: '5002',
                            tx_ahorns8colors_availabilitycolors: {
                                dateString: month,
                                remoteScriptURL: $(this.getIdSelector('BookingUrl')).val()
                            }
                        },
                        success: function (msg) {     
                            var msg = JSON.parse(msg);                       
                            calEventCache[self.getIdSelector('') + msg.d.month] = msg.d;
                            self.setMonthEvent($el, msg.d);
                            if (!ResponsiveBootstrapToolkit.is('xs')) {
                                self.initTooltip($el);
                            }
                            console.info('Loaded calendar EVENTS from API.');
                        },

                        // we shouldn't get there, but if we do ignore any errors and proceed
                        error: function () {
                            console.error('s8forms - Events konnten nicht aktualisiert werden URL: ' + this.ahorns8colorsUrl);
                            // this.process();
                        }.bind(this)
                    });
                }
            }
        },
        addMlosIcons: function ($el, dateIndex) {
            if (!!calColorCache[this.getIdSelector('') + dateIndex]) {
                for (var i = 0; i < calColorCache[this.getIdSelector('') + dateIndex].dailyRateLengthOfStay.length; i++) {
                    $el.onmCal("getDateElement",
                            calColorCache[this.getIdSelector('') + dateIndex].dailyRateLengthOfStay[i].date).append('<div class="onm-cal-mlos" />');
                }
            }
        },
        stopBootstrapFocus: function (e) {
            e.stopPropagation();
        },
        getDayNames: function () {
            var $sunday = moment("01.11.2015", "DD.MM.YYYY");
                $sunday.locale(this.lang);
            
            var $result = [];
            var $i = 0;
            
            for ($i = 0; $i < this.validationSettings.daysAllowedArrival.length; $i++) {
                if (this.validationSettings.daysAllowedArrival[$i] == 1) {
                    $result.push($sunday.clone().day($i).format("dddd"));
                }
            }
            return $result.join(', ');
        },
        getIdSelector: function (p) {
            return '#Booking' + this.uid + p;
        },
        validateNumOfPersons: function () {
            var $numOfPersons = parseInt($(this.getIdSelector('Adults')).val());
            this.$form.find('[data-target^="ChildAge"]').each(function () {
                if (parseInt($(this).val()) > 0) {
                    $numOfPersons += parseInt($(this).val());
                }
            });

            if ($numOfPersons > this.validationSettings.maxNumOfPersons) {
                toastr.clear();
                toastr.error(s8Lang.restriction.wrongNumOfPersons.replace('{0}', this.validationSettings.maxNumOfPersons), 'Hinweis:');
                return false;
            }

            return true;
        },
        validateNumOfChildren: function () {
            var $numOfChildren = 0;
            this.$form.find('[data-target^="ChildAge"]').each(function () {
                if (parseInt($(this).val()) > 0) {
                    $numOfChildren += parseInt($(this).val());
                }
            });
            if ($numOfChildren > this.validationSettings.maxNumOfChildren) {
                toastr.clear();
                toastr.error(s8Lang.restriction.maxNumOfChildren.replace('{0}', this.validationSettings.maxNumOfChildren), 'Hinweis:');
                return false;
            }
            if ($numOfChildren < this.validationSettings.minNumOfChildren) {
                toastr.clear();
                toastr.error(s8Lang.restriction.minNumOfChildren.replace('{0}', this.validationSettings.minNumOfChildren), 'Hinweis:');
                return false;
            }

            return true;
        },
        parseDate: function (selector) {
            return moment($(selector).val(), this.dateFormat);
        },
        addChildren: function () {
            var _this = this;
            this.$form.find('[data-target^="ChildAge"]').each(function () {
                var $this = $(this);

                if (parseInt($this.val()) > 0) {
                    $('#' + $this.data('target')+"-"+_this.uid).attr('name', $this.data('target').replace('ChildAge', '')).val($this.val());
                }
            });
        },
        clearForm: function () {
            this.$targetForm.find('input[type="hidden"]').each(function () {
                $(this).removeAttr('name');
            });
        },
        addOption: function (name) {
            var $element = $(this.getIdSelector(name));
            if ($element.length && $element.val() != '-1' && $element.val() != '') {
                $('.BookingForm' + name, this.$targetForm).attr('name', $('.BookingForm' + name, this.$targetForm).data('name')).val($element.val());
            }
        },
        buildForm: function () {
            var start = this.parseDate(this.getIdSelector('StartDate'));
            var end = this.parseDate(this.getIdSelector('EndDate'));
            var nights = end.diff(start, 'days');

            $('.BookingFormDate', this.$targetForm).attr('name', $('.BookingFormDate', this.$targetForm).data('name')).val(start.format('DD.MM.YYYY'));
            $('.BookingFormNights', this.$targetForm).attr('name', $('.BookingFormNights', this.$targetForm).data('name')).val('' + nights);

            this.addOption('Adults');
            this.addChildren();
            this.addOption('Rooms');
            this.addOption('RoomType');
            this.addOption('WebSalesCategory');
            this.addOption('Rate');
            this.addOption('PromotionCode');
            this.addOption('Culture');

            this.$targetForm.attr('action', $(this.getIdSelector('BookingUrl')).val());
        },
        process: function () {
            var $startDateInput = $(this.getIdSelector('StartDate')); 
            var $endDateInput = $(this.getIdSelector('EndDate')); 
 
            if (this.validateNumOfPersons() && this.validateNumOfChildren() && this.checkDates(moment($startDateInput.val(), 'L'), moment($endDateInput.val(), 'L'), false)) {
                this.$targetForm = this.$form.prev();
                this.clearForm();
                this.buildForm();
                this.$targetForm.submit();
            } else {
                return false;
            }
        }
    }

    $.fn.s8Forms = function (validationSettings) {
        var $this = $(this);
        $this.data('s8Forms', new s8Forms($this, validationSettings));
    }

    $.fn.s8FormsMulti = function (validationSettings) {
        var $bookingFormsWrapper = $(this);
        var $bookingForms = $('> .forms > div', $bookingFormsWrapper);
        $bookingForms.hide();

        $hotelSelect = $('select.hotel-select', $bookingFormsWrapper);
        var $hotelSelectOptions = $('option', $hotelSelect);

        // init forms
        $bookingForms.each(function (index) {
            var $bookingForm = $(this);
            var $correspondingSelectOption = $($hotelSelectOptions[index + 1]);

            // apply class
            $correspondingSelectOption.attr('value', $bookingForm.attr('class'));
            // note: make sure backend elements are in same order!

            // show selected
            if ($correspondingSelectOption.attr('selected') === 'selected') {
                $bookingForm.slideDown();
            }
        });

        // hotel select
        $hotelSelect.change(function () {
            console.log($hotelSelect);
            console.log($hotelSelect.val());
            $bookingForms.slideUp();
            $('.forms > .' + $hotelSelect.val(), $bookingFormsWrapper).slideDown();
        });
    }

    $.fn.initFormsOnAction = function ($parent) {

        // init booking forms
        $('.booking__form', $parent).each(function () {
            $(this).s8Forms();
        });
        
        // init multiform booking forms
        $('.booking__multiform', $parent).each(function () {
            console.log($('.booking__multiform'));
            $(this).s8FormsMulti();
        });

    }

    $(document).on('bookerLoaded.ahorn', function (evt) {

        $(this).initFormsOnAction(evt.currentTarget);

    });

    $(".modal").on('hide.bs.modal', function(e){
        // reset var, used to re-trigger ajax calls for events/colors in onm-cal
        $startDateInputTemp = false;
    });
})(jQuery);
